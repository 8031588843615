<template>
    <div class="view-container">
        <div class="header">
            <div class="login-box">
                <img src="../../assets/images/yike_logo.png" alt="">
                <div class="menu-box">
                    <el-menu router :default-active='activeIndex' mode="horizontal" @select="handleSelect">
                        <template v-for="(item,index) in adminMenu">
                            <el-menu-item :index="item.path"
                                          :key="item.name">
                                <template slot="title">
                                    <span class="title-text">{{item.meta.title}}</span>
                                </template>
                            </el-menu-item>
                        </template>
                    </el-menu>
                </div>
                <div class="user-info">
                    <div class="user-name">{{adminName}}</div>
                    <div @click="logoutBtn" class="logout-btn">退出</div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="main-container">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
    import {userLogout} from '@/utils/apis'
    import {mapState} from 'vuex'

    export default {
        data() {
            return {
                activeIndex: '/carousel',
                adminName: '',
            }
        },
        computed: mapState({
            adminMenu: state => state.adminMenu[0].children,
        }),
        mounted() {
            console.log('adminMenu', this.adminMenu)
            this.adminName = localStorage.getItem('adminName')
            let active = localStorage.getItem('adminActive');
            let routeName = this.$route.name;
            if (routeName !== 'carouselIndex' && active !== undefined && active !== null) {
                this.activeIndex = active
            }
        },
        methods: {
            handleSelect(key, keyPath) {
                this.activeIndex = key
                localStorage.setItem('adminActive', key)
            },
            // 退出
            logoutBtn() {
                userLogout().then((res) => {
                    this.$message.success('退出成功！')
                    localStorage.removeItem('adminToken')
                    localStorage.removeItem('role')
                    localStorage.removeItem('adminActive')
                    this.$router.push('/')
                })
            }
        }
    }
</script>

<style scoped lang="scss">
    .view-container {
        width: 100vw;
        height: 100vh;
        min-width: 1200px;
        background: #F7F8FC;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .header {
            height: 90px;
            background: #fff;
            display: flex;

            .login-box {
                margin: 0 auto;
                width: 1200px;
                font-size: 16px;
                height: 100%;
                display: flex;
                align-items: center;

                img {
                    height: 50px;
                    width: 94px;
                }

                .menu-box {
                    margin-left: 58px;
                    flex: 1;
                    width: 1%;

                    ::v-deep .el-menu-item {
                        padding: 0 25px;
                        font-size: 16px;
                        color: #222222;
                        border: unset;

                        &.is-active {
                            border: unset;

                            .title-text {
                                border-bottom: 4px solid #20C2C9;
                                padding-bottom: 20px;
                            }
                        }
                    }

                    ::v-deep .el-menu {

                        &.el-menu--horizontal {
                            border: unset;
                        }

                        .el-submenu {

                            .el-submenu__title {
                                font-size: 16px;
                                color: #222222;

                                .el-icon-arrow-down:before {
                                    content: '\e790';
                                    color: #222222;
                                }
                            }

                            &.is-active .el-submenu__title {
                                border-bottom: 4px solid #584EEE;
                            }
                        }
                    }
                }


                .user-info {
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;

                    .user-name {
                        width: 100px;
                        max-height: 50px;
                        text-align: right;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .logout-btn {
                        cursor: pointer;
                        margin-left: 20px;
                        color: #409EFF;
                        width: 50px;
                    }
                }
            }
        }

        .main {
            height: 1%;
            flex: 1;
            display: flex;

            .main-container {
                display: flex;
                overflow: hidden;
                position: relative;
                width: 1200px;
                margin: 20px auto 0;
                background: #fff;
                box-shadow: 0px 3px 9px 1px rgba(4, 55, 132, 0.06);
            }
        }
    }
</style>