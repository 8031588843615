import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../layout/index/IndexLayout'
import AdminLayout from '../layout/admin/AdminLayout'
import Main from '../layout/Main'

Vue.use(VueRouter)

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

export const commonRoutes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        children: [{
            path: '/',
            name: 'homeIndex',
            component: () => import('../views/index/Index'),
            meta: {
                title: '首页',
            }
        }, {
            path: '/education',
            name: 'education',
            redirect: '/education/index',
            component: Main,
            meta: {
                title: '教育服务',
            },
            children: [
                {
                    path: '/education/index',
                    name: 'educationIndex',
                    component: () => import('../views/index/education/Index'),
                    meta: {
                        title: '课程体系',
                        icon: '&#xe709;',
                        parentName: ['education'],
                    }
                },
                {
                    path: '/education/test',
                    name: 'educationTest',
                    component: () => import('../views/index/education/test'),
                    meta: {
                        title: '教育服务-测试跳转',
                        parentName: ['education', 'educationIndex'],
                        hidden: true
                    },
                },
                {
                    path: '/simulation/index',
                    name: 'simulationIndex',
                    component: () => import('../views/index/education/Simulation'),
                    meta: {
                        title: '仿真平台',
                        icon: '&#xeb63;',
                        parentName: ['education'],
                    }
                },
                {
                    path: '/informatization/index',
                    name: 'informatizationIndex',
                    component: () => import('../views/index/education/Informatization'),
                    meta: {
                        title: '信息化平台',
                        icon: '&#xe864;',
                        parentName: ['education'],
                    }
                },
                {
                    path: '/doubleTrain/index',
                    name: 'doubleTrainIndex',
                    component: () => import('../views/index/education/DoubleTraning'),
                    meta: {
                        title: '双师培训',
                        icon: '&#xe69e;',
                        parentName: ['education'],
                    }
                },
                {
                    path: '/solution/index',
                    name: 'solutionIndex',
                    component: () => import('../views/index/education/Solution'),
                    meta: {
                        title: '解决方案',
                        icon: '&#xe6ac;',
                        parentName: ['education'],
                    }
                },
            ]
        }, {
            path: '/competition',
            name: 'competition',
            redirect: '/competition/index',
            component: Main,
            meta: {
                title: '技能竞赛',
            },
            children: [
                {
                    path: '/competition/index',
                    name: 'competitionIndex',
                    component: () => import('../views/index/competition/Index'),
                    meta: {
                        title: '技能竞赛',
                        parentName: ['competition']
                    },
                },
            ]
        }, {
            path: '/case',
            name: 'case',
            redirect: '/case/index',
            component: Main,
            meta: {
                title: '精品案例',
            },
            children: [
                {
                    path: '/case/index',
                    name: 'caseIndex',
                    component: () => import('../views/index/classic_case/Index'),
                    meta: {
                        icon: '&#xe693;',
                        title: '电子商务专业建设',
                        parentName: ['case'],
                    },
                },
                {
                    path: '/case/index/enterprise',
                    name: 'caseIndexEnterprise',
                    component: () => import('../views/index/classic_case/Enterprise'),
                    meta: {
                        icon:'&#xe63d;',
                        title: '校企合作',
                        parentName: ['caseIndexEnterprise'],
                    },
                },
                {
                    path: '/case/index/modernism',
                    name: 'caseIndexModernism',
                    component: () => import('../views/index/classic_case/Modernism'),
                    meta: {
                        icon:'&#xe864;',
                        title: '现代学徒制',
                        parentName: ['caseIndexModernism'],
                    },
                },
                {
                    path: '/case/index/skills',
                    name: 'caseIndexSkills',
                    component: () => import('../views/index/classic_case/Skills'),
                    meta: {
                        icon:'&#xe69e;',
                        title: '技能竞赛',
                        parentName: ['caseIndexSkills'],
                    },
                },
                {
                    path: '/case/index/detail',
                    name: 'caseIndexDetail',
                    component: () => import('../views/index/classic_case/Detail'),
                    meta: {
                        title: '精品案例详情',
                        parentName: ['case','caseIndexDetail'],
                        hidden: true
                    },
                }
            ]
        }, {
            path: '/state',
            name: 'state',
            redirect: '/state/index',
            component: Main,
            meta: {
                title: '一课动态',
            },
            children: [
                {
                    path: '/state/index',
                    name: 'stateIndex',
                    component: () => import('../views/index/dynamic_state/Index'),
                    meta: {
                        title: '一课动态',
                        parentName: ['state']
                    },
                },
            ]
        }, {
            path: '/about',
            name: 'aboutIndex',
            component: () => import('../views/index/about/Index'),
            meta: {
                title: '关于一课',
            },
            children: []
        }, {
            path: '/applyTry',
            name: 'applyTry',
            component: () => import('../views/index/applyTry/index'),
            meta: {
                title: '申请试用',
                hidden: true,
                parentName:['']
            },
            children:[]
        },]
    },
]

export const adminRoutes = [
    {
        path: '/admin',
        name: 'admin',
        redirect: '/carousel/index',
        component: AdminLayout,
        children: [
            {
                path: '/carousel',
                name: 'carousel',
                redirect: '/carousel/index',
                component: Main,
                meta: {
                    title: '轮播管理',
                    role: 1
                },
                children: [
                    {
                        path: '/carousel/index',
                        name: 'carouselIndex',
                        component: () => import('../views/admin/carouselManage/Index'),
                        meta: {
                            title: '轮播管理',
                            role: 1,
                            parentName: ['carousel']
                        },
                    },
                    {
                        path: '/carousel/test',
                        name: 'carouselTest',
                        component: () => import('../views/index/education/test'),
                        meta: {
                            title: '轮播管理-测试跳转',
                            hidden: true,
                            role: 1,
                            parentName: ['carousel', 'carouselIndex']
                        },
                    }
                ]
            },  {
                path: '/caseManage',
                name: 'caseManage',
                redirect: '/caseManage/index',
                component: Main,
                meta: {
                    title: '案例管理',
                    role: 1
                },
                children: [
                    {
                        path: '/caseManage/index',
                        name: 'caseManageIndex',
                        component: () => import('../views/admin/caseManage/Index'),
                        meta: {
                            title: '案例管理',
                            role: 1,
                            parentName: ['caseManage']
                        }
                    },
                    {
                        path: '/caseManage/index/detail',
                        name: 'caseManageIndexDetail',
                        component: () => import('../views/admin/caseManage/Detail'),
                        meta: {
                            title: '新增案例',
                            parentName: ['caseManage'],
                            role: 1,
                            hidden: true
                        }
                    }
                ]
            }, {
                path: '/stateManage',
                name: 'stateManage',
                redirect: '/stateManage/index',
                component: Main,
                meta: {
                    title: '动态管理',
                    role: 1
                },
                children: [
                    {
                        path: '/stateManage/index',
                        name: 'stateManageIndex',
                        component: () => import('../views/admin/stateManage/Index'),
                        meta: {
                            title: '动态管理',
                            role: 1,
                            parentName: ['stateManage']
                        }
                    },
                    {
                        path: '/stateManage/AddNotice',
                        name: 'stateManageAddNotice',
                        component: () => import('../views/admin/stateManage/AddNotice'),
                        meta: {
                            title: '新增通知',
                            hidden: true,
                            role: 1,
                            parentName: ['stateManage']
                        },
                    },
                    {
                        path: '/stateManage/AddNews',
                        name: 'stateManageAddNews',
                        component: () => import('../views/admin/stateManage/AddNews'),
                        meta: {
                            title: '新增新闻',
                            hidden: true,
                            role: 1,
                            parentName: ['stateManage']
                        },
                    }
                ]
            }, {
                path: '/areaManager',
                name: 'areaManager',
                redirect: '/areaManager/index',
                component: Main,
                meta: {
                    title: '区域负责人管理',
                    role: 1
                },
                children: [
                    {
                        path: '/areaManager/index',
                        name: 'areaManagerIndex',
                        component: () => import('../views/admin/areaManager/Index'),
                        meta: {
                            title: '区域负责人管理',
                            role: 1,
                            parentName: ['areaManager']
                        }
                    },
                    {
                        path:'/areaManager/add',
                        name:'areaManagerAdd',
                        component: () => import('../views/admin/areaManager/AreaManagerAdd'),
                        meta: {
                            title:'新增区域负责人',
                            role:1,
                            parentName: ['areaManager']
                        }
                    }
                ]
            }, {
                path: '/application',
                name: 'applicationIndex',
                redirect: '/application/index',
                component: Main,
                meta: {
                    title: '申请人信息',
                    role: 1
                },
                children: [
                    {
                        path: '/application/index',
                        name: 'applicationIndex',
                        component: () => import('../views/admin/applicantInfo/Index'),
                        meta: {
                            title: '申请人信息',
                            role: 1,
                            parentName: ['application']
                        }
                    },
                ]
            },
        ]
    },
]

export const LoginRoutes = [
    {
        path: '/admin/login',
        name: 'adminLogin',
        component: () => import('../views/admin/Login'),
        meta: {
            title: '一课官网运维系统',
            role: 1
        }
    },
]

const createRouter = () => new VueRouter({
    scrollBehavior: () => ({y: 0}),
    base: process.env.BASE_URL,
    routes: commonRoutes.concat(adminRoutes).concat(LoginRoutes)
})

const router = createRouter()

export default router
