import router from './router'
import store from './store'
import {commonRoutes, adminRoutes, LoginRoutes} from '@/router'


let whiteList = ['adminLogin'] // 不需要登录就可以访问的路由白名单
commonRoutes[0].children.forEach(item => {
    if (item.children) {
        item.children.forEach(item1 => {
            whiteList.push(item1.name)
        })
    }
    whiteList.push(item.name)
})

router.beforeEach(async (to, from, next) => {
    // set page title
    document.title = to.meta.title

    // 定义3个端各自的token，方便走各自的路由逻辑，互不干扰
    const rolesToken = {
        1: 'adminToken',
    }

    //首页的内容都能看，所以直接赋值
    const indexMenu = store.getters.indexMenu && store.getters.indexMenu.length > 0;
    if (!indexMenu) {
        store.dispatch('generateIndexRoutes', commonRoutes)
    }
    const hasToken = localStorage.getItem(rolesToken[to.meta.role]) // 获取当前访问客户端的token
    if (!hasToken) {
        /* has no token*/
        if (whiteList.indexOf(to.name) !== -1) {
            next()
            return
        }
        if (to.path == '/index') {
            next()
            return
        }
        const loginRoute = LoginRoutes.find(item => item.meta.role === to.meta.role)
        next({path: loginRoute.path})
    }

    // 如果访问的路由存在对应的token，走各自流程
    switch (to.meta.role) {
        case 1:
            const adminMenu = store.getters.adminMenu && store.getters.adminMenu.length > 0;
            if (!adminMenu) {
                store.dispatch('generateAdminRoutes', adminRoutes)
            }
            next()
            break;
        default:
            next()
    }
})

router.afterEach(() => {
})