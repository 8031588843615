<template>
    <div class="view-container">
        <div class="header">
            <div class="login-box">
                <img src="../../assets/images/yike_logo.png" alt="">
                <div class="menu-box">
                    <el-menu router :default-active='activeIndex' mode="horizontal" @select="handleSelect">
                        <template v-for="(item,index) in indexMenu" v-if="!item.meta.hidden">
                            <el-submenu
                                    :index="item.path"
                                    :key="item.name"
                                    v-if="item.children && item.children.length > 1"
                                    popper-class="submenu-box"
                                    :popper-append-to-body="false">
                                <template slot="title">
                                    <span class="title-text">{{ item.meta.title }}</span>
                                </template>
                                <el-menu-item
                                        :index="subItem.path"
                                        v-for="subItem in item.children"
                                        v-if="!subItem.meta.hidden"
                                        :key="subItem.name">
                                    <div class="submenu-box-item">
                                        <div class="box-item-content">
                                            <i class="iconfont" v-html="subItem.meta.icon"></i>
                                            <div slot="title">{{ subItem.meta.title }}</div>
                                        </div>
                                    </div>
                                </el-menu-item>
                            </el-submenu>
                            <el-menu-item :index="item.path"
                                          :key="item.name"
                                          v-else>
                                <template slot="title">
                                    <span class="title-text">{{item.meta.title}}</span>
                                </template>
                            </el-menu-item>
                        </template>
                    </el-menu>
                    <div class="apply-btn" @click="toApplyTry">申请试用</div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="main-container">
                <router-view/>
            </div>
        </div>
    </div>
</template>

<script>
    // import {userLogout} from '@/utils/apis'
    import {mapState} from 'vuex'

    export default {
        data() {
            return {
                activeIndex: '/',
                levelTwoMenu: [],
            }
        },
        computed: mapState({
            indexMenu: state => state.indexMenu[0].children,
        }),
        mounted() {
            console.log('indexMenu', this.indexMenu)
            let active = localStorage.getItem('activeIndex');
            let routeName = this.$route.name;
            if (routeName != 'homeIndex' && active !== undefined && active !== null) {
                this.activeIndex = active
            }
            if (this.$route.path === '/applyTry') {
                this.activeIndex = ''
            }
        },
        watch: {
            $route(route) {
                if (route.meta.hidden) {
                    let newPath = ''
                    this.indexMenu.forEach(item => {
                        if (item.name === route.meta.parentName[0]) {
                            newPath = item.children[0].path;
                        }
                    })
                    this.activeIndex = newPath;
                    localStorage.setItem('activeIndex', newPath)
                } else {
                    if(JSON.stringify(route.query) !== '{}' && !route.query.contactUs){
                        return
                    }
                    if(route.redirectedFrom){
                        this.activeIndex = route.redirectedFrom;
                        localStorage.setItem('activeIndex', route.redirectedFrom)
                    } else {
                        this.activeIndex = route.path;
                        localStorage.setItem('activeIndex', route.path)
                    }
                }
            }
        },
        methods: {
            handleSelect(key, keyPath) {
                this.activeIndex = key
                localStorage.setItem('activeIndex', key)
            },
            // 退出
            logoutBtn() {
                this.$message.success('退出成功！')
                localStorage.removeItem('storeToken')
                localStorage.removeItem('role')
                this.$router.push('/')
            },
            toApplyTry() {
                if (this.$route.path === '/applyTry') {
                    this.activeIndex = ''
                } else {
                    localStorage.removeItem('activeIndex');
                    this.activeIndex = ''
                    this.$router.push('/applyTry')
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .view-container {
        width: 100vw;
        height: 100vh;
        min-width: 1200px;
        background: #F7F8FC;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        .header {
            height: 90px;
            background: #fff;
            display: flex;

            .login-box {
                margin: 0 auto;
                width: 1200px;
                font-size: 18px;
                height: 100%;
                display: flex;
                align-items: center;

                img {
                    height: 50px;
                    width: 94px;
                }

                .menu-box {
                    margin-left: 140px;
                    display: flex;
                    align-items: center;

                    .apply-btn {
                        width: 90px;
                        height: 26px;
                        background: #F36F21;
                        border-radius: 13px;
                        font-size: 14px;
                        color: #FFFFFF;
                        text-align: center;
                        line-height: 26px;
                        cursor: pointer;
                        flex-shrink: 0;
                    }

                    ::v-deep .el-menu-item {
                        padding: 0 35px;
                        font-size: 18px;
                        color: #222222;
                        border: unset;

                        &.is-active {
                            border: unset;

                            .title-text {
                                border-bottom: 4px solid #584EEE;
                                padding-bottom: 20px;
                            }
                        }
                    }

                    ::v-deep .el-menu {
                        flex-shrink: 0;

                        &.el-menu--horizontal {
                            border: unset;
                        }

                        .el-submenu {

                            &.is-active {

                                .title-text {
                                    border-bottom: 4px solid #584EEE;
                                    padding-bottom: 20px;
                                }
                            }

                            .submenu-box {
                                top: 65px !important;
                                left: -240px !important;
                                width: 1200px;

                                .el-menu {
                                    display: flex;
                                    height: 140px;
                                    align-items: center;

                                    &.el-menu--popup {
                                        border-radius: unset;
                                    }

                                    .el-menu-item {
                                        padding: 0 30px;
                                        height: 75px;

                                        .submenu-box-item {
                                            height: 100%;
                                            min-width: 160px;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;

                                            .box-item-content {
                                                padding: 20px;
                                                display: flex;

                                                i {
                                                    margin-right: 20px;
                                                    color: #584EEE;
                                                    font-size: 28px;
                                                }

                                                div {
                                                    max-width: 220px;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    white-space: nowrap;
                                                }
                                            }
                                        }

                                        &.is-active .submenu-box-item {
                                            background: #F1F4F8;
                                        }
                                    }

                                }
                            }

                            .el-submenu__title {
                                font-size: 18px;
                                color: #222222;
                                border: unset;

                                .el-icon-arrow-down:before {
                                    content: '\e790';
                                    color: #222222;
                                }
                            }
                        }
                    }
                }
            }
        }

        .main {
            height: 1%;
            flex: 1;
            display: flex;

            .main-container {
                width: 1%;
                flex: 1;
                display: flex;
                overflow: hidden;
            }
        }
    }
</style>