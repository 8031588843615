import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  getters: {
    indexMenu: state => state.indexMenu,
    adminMenu: state => state.adminMenu,
  },
  state: {
    indexMenu: [],
    adminMenu: [],
  },
  mutations: {
    SET_INDEX_ROUTES: (state, routes) => {
      state.indexMenu = routes
    },
    SET_ADMIN_ROUTES: (state, routes) => {
      state.adminMenu = routes
    },
  },
  actions: {
    generateIndexRoutes({ commit }, indexRoutes) {
      commit('SET_INDEX_ROUTES', indexRoutes)
    },
    generateAdminRoutes({ commit }, adminRoutes) {
      commit('SET_ADMIN_ROUTES', adminRoutes)
    },
  },
  modules: {
  }
})
