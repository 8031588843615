import http from './request'
export const userLogin = (params) => {
    return http.post('login', params)
}
export const addCase = (params) => {
    return http.post('case', params)
}
export const getCaseList = (params) => {
    return http.get('case',{params:params})
}
export const cover = (params) => {
    return http.post('case/cover', params)
}
export const logo = (params) => {
    return http.post('case/logo', params)
}
export const editCase = (id,params) => {
    return http.put('case/'+id,params)
}
export const oneCase = (params) => {
    let id = params.id;
    return http.get('case/'+id)
}
export const deleteCase = (params) => {
    let id = params.id;
    return http.delete('case/'+id)
}
export const userLogout = () => {
    return http.get('login')
}
export const getClassification = () => {
    return http.get('case/classification/list')
}
export const getSchooltype = () => {
    return http.get('case/schoolType/list')
}
export const image = () => {
    return http.get('case/content/image',{params:params})
}

export const getApplyList = (params) => {
    return http.get('apply', params)
}

export const addApplyList = (params) => {
    return http.post('apply', params)
}

export const alterApplyStatus = (id,params) => {
    return http.put('apply/' + id, params)
}

export const deleteApplyStatus = (id) => {
    return http.delete('apply/' + id)
}

export const getApplyStatus = (id) => {
    return http.get('apply/' + id)
}

export const getAreaManagerList = (params) => {
    return http.get('areaManager', params)
}

export const areaManagerAdd = (params) => {
    return http.post('areaManager/add', params)
}

export const areaManagerGet = (params) => {
    return http.get('areaManager/get', params)
}

export const areaManagerEdit = (params) => {
    return http.post('areaManager/edit', params)
}

export const areaManagerDel = (params) => {
    return http.post('areaManager/del', params)
}

export const banner = (params) => {
    return http.get('banner', {params: params})
}

export const bannerAdd = (params) => {
    return http.post('banner/add',params)
}

export const bannerUploadBanner = (params) => {
    return http.post('banner/uploadBanner',params)
}

export const bannerEdit = (params) => {
    return http.post('banner/edit',params)
}

export const bannerDel = (params) => {
    return http.post('banner/del',params)
}

export const notice = (params) => {
    return http.get('notice', {params: params})
}

export const news = (params) => {
    return http.get('news', {params: params})
}

export const noticeUploadNoticeImg = (params) => {
    return http.post('notice/uploadNoticeImg',params)
}

export const noticeAdd = (params) => {
    return http.post('notice/add',params)
}

export const noticeEdit = (params) => {
    return http.post('notice/edit',params)
}

export const noticeDel = (params) => {
    return http.post('notice/del',params)
}

export const noticeGet = (params) => {
    return http.get('notice/get', {params: params})
}

export const newsUploadNewsImg = (params) => {
    return http.post('news/uploadNewsImg',params)
}

export const newsAdd = (params) => {
    return http.post('news/add',params)
}

export const newsEdit = (params) => {
    return http.post('news/edit',params)
}

export const newsDel = (params) => {
    return http.post('news/del',params)
}

export const newsGet = (params) => {
    return http.get('news/get', {params: params})
}